// import PropTypes from "prop-types";
import React from "react";
import Layout from "../layout";

// import {
//   RichText,
// } from "../../../components/";

class PrivacyPolicy extends React.Component {
  render(){
    return (
      <Layout
        seo={{
          path: "/privacy-policy",
          title: "Privacy Policy",
        }}
      >
      <div className="px-6 py-12">
        <div className="cContainer">
          <div className="py-4">
            <h1>ANJA Life LLC Privacy Policy</h1>
            <h2>Last Updated: March 10th, 2023</h2>
          </div>
          {/* <RichText json={JSON.parse(data.richtext.raw)} /> */}
          <div className="rte">
            <p>ANJA Life LLC ("us", "we", or "our") operates <a className="text-purple !underline" href="https://www.getanja.com">https://www.getanja.com</a> (the "Site"). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site.</p>
            <h4>Information Collection and Use</h4>
            <p>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your email address ("Personal Information").</p>
            <p>We collect this information for the purpose of sending you email newsletters and promotional offers. We may also use your email address to respond to your inquiries or other requests.</p>
            <h4>Log Data</h4>
            <p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
            <h4>Cookies</h4>
            <p>Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.</p>
            <p>Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>
            <h4>Security</h4>
            <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
            <h4>Changes to This Privacy Policy</h4>
            <p>This Privacy Policy is effective as of the date stated at the top of this page and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>
            <p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>
            <h4>Contact Us</h4>
            <p>If you have any questions about this Privacy Policy, please contact us at <a className="text-purple !underline" href="mailto:info@getanja.com">info@getanja.com.</a></p>
          </div>
        </div>
      </div>
      </Layout>
  )
  }
  
  
}

export default PrivacyPolicy
